export const environment = {
  production: false,
  'base_url' : "https://cshipapp.oneleap.in/graphql?query=",
  'page_limit' : 10,
  'graphUri': "https://cshipapp.oneleap.in/graphql",
  'Apiurl': "https://cshipapp.oneleap.in/graphql?query=",
  'ApiMidUrl': "https://cshipmid.oneleap.in/graphql/",
  'laravelRoute': "https://cshipapp.oneleap.in/"
 
//  'base_url' : "http://localhost:8000/graphql?query=",
//  'page_limit' : 10,
//  'graphUri': "http://localhost:8000/graphql",
//  'Apiurl': "http://localhost:8000/graphql?query=",
//  'ApiMidUrl': "https://cshipmid.oneleap.in/graphql/",
//  'laravelRoute': "http://localhost:8000/"

};
