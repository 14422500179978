import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { Buoy, Query } from '@buoy/client';
import gql from 'graphql-tag';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../shared/services/common.service';

@Component({
  selector: 'app-pdf-generate',
  templateUrl: './pdf-generate.component.html',
  styleUrls: ['./pdf-generate.component.scss']
})
export class PdfGenerateComponent implements OnInit {

  headerList: any;
  instanceId: any;
  modelName: any;
  singleQuery: Query;
  isDataSet: boolean = false;
  formData: any;
  date: any;

  @ViewChild('content') content: ElementRef;
  @ViewChild('pdf') pdf;

  constructor(private httpService: HttpService, private buoy: Buoy, private route: ActivatedRoute, private commonService: CommonService) {}

  ngOnInit() {
    this.instanceId = this.route.snapshot.queryParamMap.get('id');
    this.modelName = this.route.snapshot.queryParamMap.get('model');
    this.getFormAndValuespdf();
  }

  pdfDownload() {}

  renderDataFunc() {
    if (this.singleQuery && this.singleQuery.hasOwnProperty('data') && this.singleQuery.data && !this.isDataSet) {
      this.isDataSet = true;
      this.formData = this.singleQuery.data;
      if (this.singleQuery.data.updated_date != null) {
        this.date = new Date(this.singleQuery.data.updated_at);
      } else {
        this.date = new Date(this.singleQuery.data.created_at);
      }

      for(var k=0; k< this.singleQuery.data.quotation_details.length; k++){
      
          this.singleQuery.data.quotation_details[k].total = (this.singleQuery.data.quotation_details[k].fpd_rate!= null) ? 
          (parseFloat(this.singleQuery.data.quotation_details[k].total) + parseFloat(this.singleQuery.data.quotation_details[k].fpd_rate)) : this.singleQuery.data.quotation_details[k].total;
      }
      
      if(this.singleQuery.data.to_cy_sd!= null && this.singleQuery.data.pre_carriage!=null){
        var t = (this.singleQuery.data.on_carriage == 'yes') ? 'SD': 'CY';
        this.singleQuery.data.to_cy_sd =  this.singleQuery.data.to_cy_sd.replace('TO_','')+' - '+t;
      }


    }
  }

  queryGraphQl(type) {
    var values = [];
    this.httpService.getHeaderData().subscribe(data => {
      if (data.length > 0) {
        this.headerList = data;
        var newItem = this.headerList.filter(item => {
          if (item.model == this.modelName) {
            return item;
          }
        });
        if (newItem.length > 0) {
          values = this.httpService.getQueryColums(newItem, type);
        }
      }
    });
    return values;
  }

  getFormAndValuespdf() {
    let queryValues = [];
    queryValues = this.queryGraphQl('edit');
    queryValues = queryValues['query'];
    if (queryValues && queryValues.length > 0) {
      var form = this.modelName + '_edit';
      queryValues.push("kyc_details{cmp_name, add_of_head_offc, city_name, state_name, country_name, telephone, fax, email_address, website }");
      
      if(this.modelName == "booking"){
        queryValues.push("quotation_details{shipping_line,container,total,priorities,fpd_rate}");
      }

      this.singleQuery = this.buoy.query(
        gql(`query ` + form + `($id: ID!){` + form + `(id: $id){` + queryValues.join() + `}}`),
        {
          id: this.instanceId,
        },
        {
          scope: form,
          fetchPolicy: 'no-cache',
          subscribe: true,
          onChange: function () {
            this.renderDataFunc();
          }.bind(this)
        });
    }
  }

  Navigate() {
    this.commonService.navigateWithQuery('/list', { model: this.modelName });
  }
}
