import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterobj'
})
export class FilterobjPipe implements PipeTransform {

  transform(filterobj: any, allobj?: any, isEql?: any, value?: any): any {
    var returnData = [];
    var filterData = allobj.filter(item => {
      if (isEql) {
        if (item[value] == filterobj[value]) {
          return item;
        }
      } else {
        if (item[value] != filterobj[value]) {
          return item;
        }
      }

    });
    for (let i = 0; i < filterData.length; i++) {
      returnData.push(filterData[i].id);
    }
    return returnData;
  }

}
