import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { LawfirmApiServiceService } from '../services/lawfirm-api-service.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  layoutSub: Subscription;
  // sideBar:any = [
  //   { path: '/list', title: 'PO List',  modelName: 'buyer_polist', icon: 'ft-droplet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //   ,{path: '/shipmentlist',   modelName: '', title: 'Shipment List',    icon: 'ft-droplet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //   { path: '/booking/ship-rate',   modelName: '',title: 'Ship Rate',  icon: 'ft-droplet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //   { path: '/register', title: 'Company Registration', modelName: '',  icon: 'ft-droplet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  // ];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private layoutService: LayoutService,
    private lawfirmApiServiceService : LawfirmApiServiceService,
    private httpService: HttpService
  ) {
    if (this.depth === undefined) {
        this.depth = 0;
        this.expanded = true;
    }

    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
      options => {
        if (options) {
          if (options.bgColor) {
            if (options.bgColor === 'white') {
              this.logoUrl = 'assets/img/logo-dark.png';
            }
            else {
              this.logoUrl = 'assets/img/logo.png';
            }
          }

          if (options.compactMenu === true) {
            this.expanded = false;
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = true;
          }
          else if (options.compactMenu === false) {
            this.expanded = true;
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = false;
          }

        }
      });
  }


  ngOnInit() {
    this.config = this.configService.templateConf;
    this.httpService.getHeaderData().subscribe(data=>{
      if(data.length > 0){
        this.httpService.getRole().subscribe(role=>{
          if(role[0]["name"]!= ''){ 
            if(role[0]["name"]=='superAdmin' || role[0]["name"]=='admin'){
              this.menuItems = ROUTES;
              this.menuItems = this.menuItems.concat(data);  
            }else{
              this.menuItems = ROUTES.filter(item=>{
                if(item.title == "Dashboard"){
                    return (item);
                } 
              });
              this.menuItems = this.menuItems.concat(data); 
            }       
          }
        });  

        // for(let i=0; i < data.length ; i++){
        //   // if(data[i].type == 'header'){
        //     this.menuItems.push({ 
        //       'path': '/lists',
        //       'title': data[i].title, 
        //       'modelName' : data[i].modelName,
        //       'icon': 'ft-droplet',
        //       'isList' : true,
        //       'class': '', 
        //       'isClicked' : false,
        //       'badge': '', 
        //       'badgeClass': '', 
        //       'isExternalLink': false, 
        //       'submenu': [] 
        //     });
        //   // }
        // }
      }
    });

    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }
   
    
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        }
        else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
        }
      }
      this.activeTitles = [];
    }, 0);

    
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  setModel(menuItem){ 
    if(menuItem.hasOwnProperty('modelName')){
      this.httpService.setModelName({'modelName':menuItem.modelName}); 
      this.httpService.setRefName(menuItem.title);

      let setFormComponent = {};
      this.httpService.setFormComponent(setFormComponent);
      this.router.navigate([menuItem.path], {
        relativeTo: this.route,
        queryParams: {
        model: menuItem.modelName
        },
        queryParamsHandling: 'merge',
        // preserve the existing query params in the route
        skipLocationChange: false
        // do not trigger navigation
        });
      // this.lawfirmApiServiceService.setActiveModelName(menuItem.model);
    }else{
      if(menuItem.hasOwnProperty('path')){
        this.router.navigate([menuItem.path]);
      }
    }
 //  this.resetAllClickMenu();
  // menuItem.isClicked = true;
  }

  // resetAllClickMenu(){
  //   for(let i =0 ;i < this.menuItems.length; i++){
  //     this.menuItems[i].isClicked =  false;
  //   }
  // }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
}
