import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableViewComponent } from '../data-table-view/data-table-view.component';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReplacePipe } from '../replace.pipe';

import { MatRadioModule } from '@angular/material';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PdfGenerateComponent } from '../pdf-generate/pdf-generate.component';

import { NgxSpinnerModule } from "ngx-spinner";

import { ExcelSummaryComponent } from '../excel-summary/excel-summary.component';

 
@NgModule({
  declarations: [
    DataTableViewComponent, ReplacePipe,
    PdfGenerateComponent,
    ExcelSummaryComponent,

  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    MatRadioModule,
    PDFExportModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule    
  ],
  exports: [DataTableViewComponent, PdfGenerateComponent, NgxSpinnerModule,NgxDatatableModule, MatRadioModule, PDFExportModule,ExcelSummaryComponent,FormsModule,ReactiveFormsModule],
  providers: [],
  entryComponents: [],
})
export class CommonExportsModule { }

