import { Pipe, PipeTransform } from '@angular/core';
import { LawfirmApiServiceService } from '../shared/services/lawfirm-api-service.service';
import * as moment from 'moment';

@Pipe({
  name: 'valueFormat'
})
export class ValueFormatPipe implements PipeTransform {
  dateFormat = "DD-MM-YYYY";
  timeFormat = "hh:mm a";
  constructor(
    private lawfirmApiServiceService: LawfirmApiServiceService
  ) {
    // this.lawfirmApiServiceService.getmasterSettings().subscribe(data => {
    //   if (data.length > 0) {
    //     var tempdata = data.filter(item => {
    //       if (item['key'] == 'default_date_format') {
    //         return item;
    //       }
    //     });
    //     if (tempdata.length > 0) {
    //       this.dateFormat = tempdata[0]['value'][0].value;
    //     }
    //     var newtempdata = data.filter(item => {
    //       if (item['key'] == 'default_time_format') {
    //         return item;
    //       }
    //     });

    //     if (newtempdata.length > 0) {
    //       this.timeFormat = newtempdata[0]['value'][0].value;
    //     }

    //   }
    // })
  }

  transform(value: any, args?: any): any {
    var isDate = this.isValidDate(value); 
    var timef = (args == 1) ? '' : ' ' + this.timeFormat;
    if (isDate) {
      var date = moment(new Date(value), this.dateFormat + timef);
      return date.format(this.dateFormat + timef);
    } else {
      return value;
    }
  }

  isValidDate(str) {
    var regex = new RegExp("([0-9]{4}[./-](0[1-9]|1[0-2])[./-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[./-](0[1-9]|1[0-2])[./-][0-9]{4})");
    return regex.test(str);

    // var d = moment(str, this.dateFormat);
    // return d.isValid();
    // if (d == null || !d.isValid()) return false;

    // return str.indexOf(d.format('D-M-YYYY')) >= 0
    //   || str.indexOf(d.format('DD-MM-YYYY')) >= 0
    //   || str.indexOf(d.format('D-M-YY')) >= 0
    //   || str.indexOf(d.format('DD-MM-YY')) >= 0;
  }

}
