import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from '@angular/router';
// import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { ChartistModule } from 'ng-chartist';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient,HTTP_INTERCEPTORS } from "@angular/common/http";

//custom interceptor for adding token in request
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar'; 

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

// import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";

import { BuoyModule, BuoyConfig } from '@buoy/client';
import { environment } from '../environments/environment';
import { CustomPreloading } from './custom-preloading';
// import { ShipsearchpageComponent } from './shipsearchpage/shipsearchpage.component';
// import { FormEditComponent } from './form-edit/form-edit.component';
// import { FormViewComponent } from './form-view/form-view.component';
import  { FormsModule } from '@angular/forms';
import  { CustomErrorHandler } from './config/custom-error-handler';

// import { TierWiseMarginComponent } from './tier-wise-margin/tier-wise-margin.component';



// import { BookingComponent } from './booking/booking.component';
// import { KycCommentsComponent } from './kyc-comments/kyc-comments.component';


const buoyConfig = <BuoyConfig> {
  // Remember to add graphUri to your environment-file.
  uri: environment.graphUri
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  // return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    
    // BookingComponent,
    // FormEditComponent,
    // FormViewComponent,
    // KycCommentsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    // NgSelectModule,
    ChartistModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    IonicModule.forRoot(),
    BuoyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyCERobClkCv1U4mDijGm1FShKva_nxsGJY"
    // }),
    PerfectScrollbarModule,
    FormsModule,
    
  ],
  providers: [
    AuthService,
    AuthGuard,
    SplashScreen,
    StatusBar,
    // DragulaService,
    CustomPreloading,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: BuoyConfig, useValue: buoyConfig },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: CustomErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
