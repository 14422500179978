
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { timer, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export class CustomPreloading implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = (delay) => delay ? timer(10000).pipe(mergeMap(_ => load())) : load();
    console.log(route.data);
    return route.data && route.data.preload ? loadRoute(route.data.delay) : of(null);
  }
}