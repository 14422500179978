import { Routes, RouterModule } from '@angular/router';
// import { ListingViewComponent } from '../../listing-view/listing-view.component';
// import { CreateFormModule } from '../../create-form/create-form.module';
// import { DashboardModule } from '../../dashboard/dashboard.module';
// import { FullPagesModule } from '../../pages/full-pages/full-pages.module';

// import { FormBuilderComponent } from '../../form-builder/form-builder.component';
// import { FormRendererComponent } from '../../form-renderer/form-renderer.component';
// import { ShipsearchpageComponent } from '../../shipsearchpage/shipsearchpage.component';
// import { FormEditComponent } from '../../form-edit/form-edit.component';

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    // loadChildren: 'app/dashboard/dashboard.module#DashboardModule',
    // loadChildren: () => DashboardModule,
    data: { preload: true, delay: false }
  },
  {
    path: 'list',
    loadChildren: './listing-view/listing-view.module#ListingViewModule',
    // component: ListingViewComponent,
    data: { title: 'Listing Views', preload: true, delay: false }
  },
  // { 
  //   path: 'edit',
  //   component: FormEditComponent,
  //   data: { title: 'Edit Views' }
  // },
  // {
  //   path: 'modelForms',
  //   loadChildren : './create-form/create-form.module#CreateFormModule',
  //   data: { preload: false }
  //   // loadChildren: () ={
  //   path : 'searchShipRates',
  //   loadChildren: './shipsearchpage/shipsearchpage.module#ShipsearchpageModule',
  //   data: { title: 'Ship Search Page', preload: true, delay: false }
  //   // component : ShipsearchpageComponent
  // },> CreateFormModule
  // },
  // {
  //   path: 'createForm',
  //   loadChildren: './form-builder/form-builder.module#FormBuilderModule',
  //  // component: FormBuilderComponent,
  // },
  // {
  //   path : 'create',
  //   component : FormRendererComponent
  // },
  {
    path: 'forms',
    loadChildren: './form-io/form-io.module#FormIoModule',
    // component: FormBuilderComponent,
  },
  {
    path: 'searchShipRates',
    loadChildren: './shipsearchpage/shipsearchpage.module#ShipsearchpageModule',
    data: { title: 'Ship Search Page', preload: true, delay: false }
    // component : ShipsearchpageComponent
  },
  {
    path: 'booking',
    loadChildren: './booking/booking.module#BookingModule',
    data: { title: 'Booking Page', preload: true, delay: false }
  },
  {
    path: 'view',
    loadChildren: './view-data/view-data.module#ViewDataModule',
    data: { title: 'View Data', preload: true, delay: false }
  },
  {
    path: 'pdf-generate',
    loadChildren: './pdf-generate/pdf-generate.module#PdfGenerateModule',
    data: { title: 'Pdf Generate', preload: true, delay: false }
  },

];
