import { Component, OnInit,Input } from '@angular/core';
import { CommonService } from '../shared/services/common.service';
import { HttpService } from '../shared/services/http.service';
@Component({
  selector: 'app-excel-summary',
  templateUrl: './excel-summary.component.html',
  styleUrls: ['./excel-summary.component.scss']
})
export class ExcelSummaryComponent implements OnInit {

  public modelName: any;
  constructor(private commonService: CommonService, private httpService: HttpService) { 
    this.httpService.getModelName().subscribe(data => {
        if (data.hasOwnProperty('modelName')) {
          this.modelName = data['modelName'];
        }
    });

  }

  @Input('excelDataCount') excelDataCount;
  @Input('InsertedDataCount') insertedDataCount;

  ngOnInit() {
  }


  navigateToList(){
    this.commonService.navigateWithQuery('/list', { model: this.modelName });
  }
}
