import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userListViews'
})
export class UserListViewsPipe implements PipeTransform {

  transform(args?: any, modelName?: any): any {
    var instanceHeader = args.filter(item => {
      if (modelName) {
        if (modelName == item.model_name && item.user_id != 0) {
          return item;
        }
      }

    });
    return instanceHeader;
  }

}
