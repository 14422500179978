import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
 
//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
// pipe

// import { ListingViewComponent } from '../listing-view/listing-view.component';
// import { CommonExportsModule } from '../common-exports/common-exports.module';
// import { CustomSearchComponent } from '../custom-search/custom-search.component';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
// import { TagInputModule } from 'ngx-chips';
// import { DualListboxComponent } from '../dual-listbox/dual-listbox.component';
// import { AngularDualListBoxModule } from 'angular-dual-listbox';

import { CommonExportsModule } from '../common-exports/common-exports.module';

import { RemoveHtmltagPipe } from '../_pipe/remove-htmltag.pipe';
import { headerList } from '../_pipe/headerfilter.pipe';
import { UserListViewsPipe } from '../_pipe/user-list-views.pipe';
import { FilterobjPipe } from '../_pipe/filterobj.pipe';
import { ValueFormatPipe} from '../_pipe/value-format.pipe';
// import { DataTableViewComponent } from '../data-table-view/data-table-view.component';
//import { FormRendererComponent } from '../form-renderer/form-renderer.component';
// import { FormioModule, FormioAppConfig } from 'angular-formio';

// import { FormioResources } from 'angular-formio/resource';
// import { FormioAuthService, FormioAuthConfig } from 'angular-formio/auth';

// import { FormioGrid } from 'angular-formio/grid';
// import { FormioResources } from 'angular-formio/resource';

// import { KycCommentsComponent } from './../kyc-comments/kyc-comments.component';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        TranslateModule,
        //FormioModule,
        CommonExportsModule,
        headerList
        // KycCommentsComponent
        // DataTableViewComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        PerfectScrollbarModule,
        CommonExportsModule,
        FormsModule,
        ReactiveFormsModule,
        // TagInputModule,
        // AngularDualListBoxModule,
        //FormioModule,

    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        RemoveHtmltagPipe,
        headerList,
        UserListViewsPipe,
        FilterobjPipe,
        ValueFormatPipe
        // CustomSearchComponent,
        // ListingViewComponent,
        // DualListboxComponent,
        // KycCommentsComponent
        // DataTableViewComponent
    ],
    entryComponents: [
        // CustomSearchComponent,
        // DualListboxComponent

    ],
    providers:[
        headerList,
        UserListViewsPipe,
        FilterobjPipe,
        ValueFormatPipe
    ]
    
})
export class SharedModule { }
