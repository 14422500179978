import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'headerList'
})

export class headerList implements PipeTransform {
    transform(value: string, args?: any, modelName?: any, admin?: any): any {
        var instanceHeader = args.filter(item => {
            if (modelName) {
                if (!admin) {
                    if (modelName == item.model_name && item.type == value) {
                        return item;
                    }
                } else {
                    if (modelName == item.model_name && item.type == value && item.user_id == '0') {
                        return item;
                    }
                }

            } else {
                if (item.type == value) {
                    return item;
                }
            }

        });
        return instanceHeader;
    }
}