import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  // ViewEncapsulation,
  ViewChild,
  // Renderer2,
  ElementRef,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewRef
} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LawfirmApiServiceService } from '../shared/services/lawfirm-api-service.service';
import { HttpService } from '../shared/services/http.service';
import { headerList } from '../_pipe/headerfilter.pipe';
import { FilterobjPipe } from '../_pipe/filterobj.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../shared/services/common.service';
import { ValueFormatPipe } from  '../_pipe/value-format.pipe';
@Component({
  selector: 'app-data-table-view',
  templateUrl: './data-table-view.component.html',
  styleUrls: ['./data-table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableViewComponent implements OnInit {
  headerHeight = 50;
  rowHeight = 50;
  isDataAvailable: boolean = false;
  allIndex = [];
  columns: any;
  listData: any;
  virtualization: boolean = true;
  columnNames: any;
  activeModelName: any;
  listviews: any;
  customCoulmnsFilter: any;
  rows: any = [];
  isCustomFilterSet: boolean = false;
  dataArray: any;
  editing = {};
  columnWidth = 60;
  scrollValue: number = 0;
  userInfo: any;
  customActionsList = [];
  modelPermissions: any = {
  };
  headerListItem: any;
  extraColumns = [];
  selected = [];
  listselected = [];
  allRowsSelected = false;
  console = console;
  pageLimitOptionsVal = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];
  currentPageLimit = 10;

  @ViewChild('myTable') table: any;

  @Input()
  set listDisplayData(value) {
    this.listData = value;
    this.renderDataAndCoulmns();
    this.customdetectChange();
  }

  @Input()
  set customActions(value) {
    this.customActionsList = value;
    // if(this.customActionsList.length > 0){
    //   this.renderCustomActions()
    // }
  }
  @Input('showErrorField') showErrorFieldData:any = false;

  @Input('displayData') displayData;
  @Input() set pageLimitOptions(value) {
    this.resetValues();
    this.customdetectChange();
  }

  @Input('isFormView') isFormViewvar:boolean = false;
  @Input('displaySaveQuote') displaySaveQuotes: any = false;
  @Input('hideHeaderColumn') hideHeaderColumns: any = false;
  @Input('rate_request_rate_applied_on') rate_applied_on: any = 1;

  @Input('displayBookBtn') displayBookBtn:boolean = false; 
  @Output() pageChangeTriggered: EventEmitter<any> = new EventEmitter();
  @Output() exportCSVTriggers: EventEmitter<any> = new EventEmitter();
  @Output() customActionClicked: EventEmitter<any> = new EventEmitter();
  @Output() deleteTriggers: EventEmitter<any> = new EventEmitter();
  @Output() showCustomView: EventEmitter<any> = new EventEmitter();

  /* Function to handle resize event - calculate datatable prop again  */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.renderDataAndCoulmns();
    this.customdetectChange();
    // this.table.recalculate();
  }

  constructor(
    private lawfirmApiServiceService: LawfirmApiServiceService,
    private httpService: HttpService,
    private el: ElementRef,
    private router: Router,
    private headerList: headerList,
    private route: ActivatedRoute,
    private filterobj: FilterobjPipe,
    private valueFormat: ValueFormatPipe,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private commonService: CommonService
  ) {
    this.cd.detach();
    this.httpService.getGlobalConstants().subscribe(data => {
      (data) && (this.userInfo = data);
      this.customdetectChange();
    });

    this.httpService.getSelectedRows().subscribe(data => {
      if (data) {
        this.selected = data;
      }
    });

    this.httpService.getHeaderData().subscribe(data=>{
      if(data.length > 0){
        this.headerListItem = data;
      }
    });

    this.httpService.getModelName().subscribe(data => {
      if (data) {
        this.resetValues();
        if(data && data.hasOwnProperty('modelName')){
          this.activeModelName = data['modelName'];
        }
        this.modelPermissions = this.getPermissionAgainstModel();
        this.customdetectChange();
      }
    });

    

    this.httpService.getListViewData().subscribe(data => {
      this.listviews = data;
    });

    this.httpService.getCustomColumnFilter().subscribe(data => {
      if (data.hasOwnProperty('confirmed') && data['confirmed'].length > 0) {
        this.isCustomFilterSet = true;
        this.customCoulmnsFilter = data;
        this.renderColumns(this.customCoulmnsFilter['confirmed']);
      } else {
        this.isCustomFilterSet = false;
        this.renderSourceCoulmns();
      }
     // this.renderExtraFilterColumns();
      this.customdetectChange();
    });

    this.httpService.getisExportCSVTriggerd().subscribe(data => {
      if (data) {
        var dataTable = [];
        var tableData = this.selected.length > 0 ? this.selected : this.rows;
        // var tableData = this.listselected.length > 0 ? this.listselected : this.rows;
        // var a = this.listselected;
        for (let j = 0; j < tableData.length; j++) {
          var rowValue = tableData[j];
          // tableData.filter(rowValue => {
          var tempObj = {};
          for (let i = 0; i < this.extraColumns.length; i++) {
            var columnValue = this.extraColumns[i].name;
            tempObj[columnValue] = rowValue[this.extraColumns[i].prop] != undefined ? rowValue[this.extraColumns[i].prop] : 'NA';
          }
          dataTable.push(tempObj);
          // return tempObj;
          // });
        }

        this.exportCSVTriggers.emit(dataTable);
        this.httpService.setisExportCSVTriggerd(false);
      }
      this.customdetectChange();
    })
  }

  ngOnInit() {
  }

  // renderCustomActions(){
  //   this.customActionsList
  // }

  valueFilter(value, row) {
    var hour_format = (row && row.hour_format) ? row.hour_format : 1;
    return this.valueFormat.transform(value, hour_format);
  }

  customdetectChange() {
    setTimeout(function () {
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    }.bind(this), 50);
  }

  getPermissionAgainstModel() {
      var modelPermit = {};
      if(this.headerListItem !=undefined  && this.headerListItem.length > 0){      
        var permissionList = this.headerListItem.filter(item => {
          if (item.model == this.activeModelName) {
              return item.permissionData;
          }
        });
        if(permissionList.length > 0){
          if(permissionList[0].hasOwnProperty('permissionData')){
              permissionList[0]['permissionData'].forEach(permit => {
                modelPermit[permit] = true;
              });
          }
        }
    }
      return modelPermit;
  }

  // onCheckboxChangeFn(event, value) {
  //   if (event.target.checked == true) {
  //     this.selected.push(value);
  //   } else {
  //     //for filter equal value pass true and vice versa.
  //     this.selected = this.filterobj.transform(value, this.selected, false, 'id');
  //   }
  //   this.lawfirmApiServiceService.setSelectedRows(this.selected);
  //   this.customdetectChange();
  // }

  deleteData(value) {
    this.deleteTriggers.emit(value.id.toString());
    this.customdetectChange();
  }

  onSelect({ selected }) {
    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
    if (selected) {
      this.httpService.setSelectedRows(selected);
    } else {
      this.httpService.setSelectedRows(this.selected);
    }
    // 
    // this.httpService.setSelectedRows(this.selected); 
    this.customdetectChange();
  }

  onSort($event) {
    this.customdetectChange();
  }

  voucherViewHandler(type, row) {
    this.showCustomView.emit(row);
  }

  resetValues() {
    this.allIndex = [];
    this.rows = [];
    this.scrollValue = 0;
    this.dataArray = [];
    this.customdetectChange();
    // setTimeout(function () {
    //   document.getElementsByTagName('datatable-body').length > 0 && (document.getElementsByTagName('datatable-body')[0].scrollTop = 1);
    // }, 0);
  }

  renderDataAndCoulmns() {
    if (this.listData) {
      (!this.isCustomFilterSet) ? (this.renderSourceCoulmns()) : this.renderColumns(this.customCoulmnsFilter['confirmed']);
      
      //uncomment below line and turn off virtualization + vertical scroll for normal pagination

      if (this.displayData == undefined) {
        this.displayData = {};
        this.displayData.total = this.listData.data.length;
        this.displayData.currentPage = 1;
        this.displayData.perPage = 10;
      }

      setTimeout(function () {
        this.rows = null;
        this.rows = [...this.listData.data];
        this.rows = [...this.rows];
        this.customdetectChange();
        this.table.recalculate();
      }.bind(this), 50);
    }
   
  }

  customActionClickHandler(inst, rowData) {
    this.customActionClicked.emit({
      'inst': inst,
      'rowData': rowData
    });
  }


  editViewClickHandler(action, row) {
    this.httpService.setFormDataToEdit({
      'model_name': this.activeModelName,
      'id': row.id,
      'user': row.user,
      // id: 109,
      'isReadOnly': action == 'edit' ? false : true,
      'isViewOnly': action == 'edit' ? false : true,
      quotation_number: row.hasOwnProperty('quotation_number') ? row.quotation_number : '',
    });
    // this.router.navigate(['/editForm']);
    var path = (action == 'edit') ? '/forms/edit' : '/forms/edit';
    (this.activeModelName == 'spot_booking') && (path = '/searchShipRates');
    (this.activeModelName == 'booking') && (path = '/pdf-generate');  
    (this.activeModelName == 'rate_request' && action == 'view') && (path= 'view/rate-request');
    this.commonService.navigateWithQuery(path, { model: this.activeModelName, id: row.id , edit: action == 'edit' ? true : false});

  }

  renderSourceCoulmns() {
    if (this.listviews) {
      let namesList = this.headerList.transform('header', this.listviews, this.activeModelName, true);
      // namesList = Object.keys(JSON.parse(namesList[0].value));
      if(namesList.length > 0){
          namesList = Object.keys(JSON.parse(namesList[0].query_field)).map(valueKey => { return JSON.parse(namesList[0].query_field)[valueKey] });
      }  
     
      // let namesList = Object.keys(this.listData.data[0]);
      // namesList = namesList.filter(d => d != "__typename");

      (!this.isFormViewvar) &&  this.renderColumns(namesList);
    }
    this.customdetectChange();

  }

  onPage(pageDetails) {
    window.event && (this.scrollValue = 0);
    this.pageChangeTriggered.emit(pageDetails);
    this.customdetectChange();
  }

  onScroll(event) {
    if (event.offsetY) {
      this.scrollValue = event.offsetY != 0 ? event.offsetY : this.scrollValue;
    }
    var element = this.el.nativeElement.getElementsByTagName('datatable-body')[0];
    if (element.scrollHeight - element.scrollTop === element.clientHeight && this.listData && this.listData.hasOwnProperty('data')) {
      this.pageChangeTriggered.emit({ offset: this.listData.paginatorInfo.currentPage });
    }
  }

  renderColumns(coulumns) {

    // Change according  to _local code to change 
    let tempObj = [];
    var keys = this.headerList.transform('header', this.listviews, this.activeModelName, !this.isCustomFilterSet);
    if (keys.length > 0) {
      keys = this.isCustomFilterSet ? this.httpService.swapJsonKeyValue(JSON.parse(keys[0].query_field)) : Object.keys(JSON.parse(keys[0].query_field));
    }
    
    if (this.listData && Object.keys(this.listData).length > 0 && this.listData.hasOwnProperty('data') && this.listData.data.length > 0) {
      let namesList = Object.keys(this.listData.data[0]);
      namesList = namesList.filter(d => d != "__typename");

      if (namesList.length > 0 && (this.hideHeaderColumns || this.activeModelName == 'selling_rate')) {
          keys     = namesList;
          coulumns = namesList;
      }
    }

    // swapJsonKeyValue
    for (let i = 0; i < coulumns.length; i++) {
      if (coulumns[i] != "id" && coulumns[i] != "user" && coulumns[i]!= 'Errors') {
        tempObj.push({
          'name': coulumns[i],
          // 'prop': coulumns[i].toLowerCase()
          'prop': this.isCustomFilterSet ? keys[coulumns[i]] : keys[i]
        });
      }
    }
    this.columns = tempObj;
    this.renderDefaultExtraColumns();
    this.customdetectChange();
  }

  renderDefaultExtraColumns() { 
    var defaultColumnsWidth = 30 + 50 + 120 + 20;
    if (this.table) {
      var tableWidth = this.table._innerWidth - defaultColumnsWidth;
      this.columnWidth  = (tableWidth / this.columns.length) > 140 ? (tableWidth / this.columns.length) : 140;
      this.extraColumns = (tableWidth != 0) ? this.columns.splice(0, Math.round(this.table.element.offsetWidth / 180)) : this.columns.splice(0, 1);
      this.table.recalculate();
    } else {
      this.columnWidth = 170;
      this.extraColumns = this.columns.splice(0, 1);
    }

  }

  renderExtraFilterColumns() {
    if (this.listData && Object.keys(this.listData).length > 0 && this.listData.hasOwnProperty('data') && this.listData.data.length > 0) {
      var data = Object.keys(this.listData.data[0]);
      for (let i = 0; i < this.extraColumns.length; i++) {
        data.splice(data.indexOf(this.extraColumns[i].name), 1)
      }
      var tempObj = [];
      var keys = this.headerList.transform('header', this.listviews, this.activeModelName);
      if (keys.length > 0) {
        keys = Object.keys(JSON.parse(keys[0].query_field));
      }
      // if(this.listData){
      //   let namesList = Object.keys(this.listData.data[0]);
      //   namesList = namesList.filter(d => d != "__typename");
      //   if (namesList.length > 0 && this.hideHeaderColumns) {
      //       keys = namesList;
      //       coulumns = namesList;
      //   }
      // }

      for (let j = 0; j < data.length; j++) {
   
        if (data[j] != '__typename' || data[j] != 'id' || data[j] != 'user' || data[j]!= 'Errors') {
          tempObj.push({
            'name': data[j],
            'prop': keys[j]
          });
        }
      }
      this.columns = tempObj;
    }
    this.customdetectChange();
  }

  onDetailToggle(event) {
    //cell click handler
    this.customdetectChange();
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    this.customdetectChange();
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
    this.customdetectChange();
  }

  displayCheck(row) {
    return true;
  }

  getRowClass(row) {
    var flag = (row.conflict && row.conflict != "") ? true : false;
    return {
      'conflicted-row-color': flag
    };
  }


  // createNewRecordHandler(event) {
  //   this.router.navigate(['/create']);
  // }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.rows.filter(function (d) {
      return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    this.customdetectChange();
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }


  rowVisibility(row, permission) {
    const conditions = {
      kyc: {
        edit: {
          Approved: true,
          Rejected:true,
          Pending:true
        },
        delete: {
          Approved: false,
          Rejected: true,
          Pending:  true
        },
        view:{
          Approved: true,
          Rejected:true,
          Pending:true
        }
      },
      rate_request:{
        edit:{
          Completed:false,
          Pending:true
        },
        view:{
          Completed:true,
          Pending:false
        }
      }
    };
    if (conditions[this.activeModelName] && conditions[this.activeModelName][permission]) {
      // var rowVal = this.userInfo.id == row.user ? 'me' : 'other';
      var statusCond = conditions[this.activeModelName][permission];
      if (statusCond && statusCond[row.status] != undefined) {
        return statusCond[row.status];
      }
    }
    return true;
  }


  listForm(path){
    var queryParams = {
      'model':this.activeModelName
    }
    this.router.navigate(['/'+path], {
      relativeTo: this.route,
      queryParams: queryParams,
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
  });

  }


}
