import { Component, ViewContainerRef, OnInit, OnDestroy, ChangeDetectorRef, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/auth/auth.service';
import { LawfirmApiServiceService } from './shared/services/lawfirm-api-service.service';
import { HttpService } from './shared/services/http.service';
import { environment } from '../environments/environment';
import 'rxjs/add/observable/of';
import { Buoy, Query } from '@buoy/client';
import gql from 'graphql-tag';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ItemsList } from '@ng-select/ng-select/ng-select/items-list';

@Component({
    selector: 'app-root',   
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    console = console;
    subscription: Subscription;
    graphQlQuery: Query;
    graphQlKYCQuery: Query;
    isQueryFired: boolean = false;
    isDataSet: boolean = false;
    isKYCDataset: boolean = false;
    showLoader: boolean = true;
    activePageLimit = environment.page_limit;
    currentActivePage: number = 1;
    role: any;
    user_id: any;
    activeModalName: string;
    query_subscribe: boolean = false;
    queryParam: any;
    constructor(
        private http: HttpClient,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public  navCtrl: NavController,
        private router: Router,
        private authService: AuthService,
        private buoy: Buoy,
        private lawfirmApiServiceService: LawfirmApiServiceService,
        private ref: ChangeDetectorRef,
        private httpService: HttpService,
        private activatedRoute: ActivatedRoute
    ) {
        this.initializeApp();

        // this.activatedRoute.queryParams.subscribe(params => {
        //     let model = params['model'];
        //      if(model!=''){

        //      }
        // });


    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            var graphURI = environment.graphUri;
            var graphArr = graphURI.split('/');
            var domainName = graphArr[graphArr.length - 2];
            // alert('hello');
            this.initAllConstructorFunc();
        }).catch(() => { });
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('model=') != -1) {
                    this.queryParam = event;
                    this.query_subscribe = true;
                }
            }
        });
    }



    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    getDataForListingMenu() {
        this.graphQlQuery = this.buoy.query(
            gql(`query me{me{id,name,email,permission{name},role{name},model_list{model_name},list_view{user_id,model_name,title,query_field,type}}}`),
            {},
            {
                scope: 'me',
                fetchPolicy: 'no-cache',
                subscribe: true,
                onChange: function ($event) {
                    this.getResponseFromMeQuery($event);
                }.bind(this)
            },
        );
    }

    getChildrenList(permissionArr) {
        var returnData = [];
        let allowedRoute = ['create', 'view'];
        for (let i = 0; i < permissionArr.length; i++) {
            var defaultPermitRoute = {
                'view': {
                    'name': "list",
                    "path": "/list",
                    "icon":"ft-list"
                },
                'create': {
                    'name': "create",
                    "path": "/forms/create",
                    "icon":"ft-plus"
                },
                'search': {
                    'name': "search",
                    "path": "/search"
                }
            }
            let permit = permissionArr[i]['permission'];
            if (allowedRoute.includes(permit)) {
                returnData.push({
                    path: defaultPermitRoute[permit].path,
                    isClicked: true,
                    modelName: permissionArr[i]['model_name'],
                    list_query: permissionArr[i]['list_query_key'],
                    title: defaultPermitRoute[permit].name,
                    icon: defaultPermitRoute[permit].icon,
                    class: '',
                    badge: '',
                    badgeClass: '',
                    isExternalLink: false,
                    submenu: [{ path: defaultPermitRoute[permit].path,
                        isClicked: true,
                        modelName: permissionArr[i]['model_name'],
                        list_query: permissionArr[i]['list_query_key'],
                        title: defaultPermitRoute[permit].name,
                        icon:  '',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false}]
                });
            }
        }
        return returnData;
    }

    getResponseFromMeQuery() {
        if (this.graphQlQuery && this.graphQlQuery.hasOwnProperty('data') && this.graphQlQuery.data) {
            var list_view      = this.graphQlQuery.data.list_view;
            var permissionData = this.graphQlQuery.data.permission;
            var form_data = this.graphQlQuery.data.model_list;
            var role = this.graphQlQuery.data.role;
            this.httpService.setRole(role);
            this.httpService.setListViewData(list_view);
            var LISTINGDATA = [];
            list_view.forEach(item => {
                if(item.user_id == 0){
                    LISTINGDATA.push(item);
                } 
            });
            this.getDataFromKYC(LISTINGDATA, permissionData, form_data, role);
        }
    }

    getDataFromKYC(list_view, permissionData, form_data, role) {
        this.role = role[0].name;
        var limitObject = {
            limit: this.activePageLimit,
            page: this.currentActivePage,
            delete_flag: 0
        }
        var user_type = "";
        var user_value = "";
        if (this.role != 'superAdmin') {
            limitObject['user_id'] = this.user_id;
            user_type = ", $user_id: String!";
            user_value = ", user_id:$user_id";
        }
        this.graphQlKYCQuery = this.buoy.query(
            gql(`query kyc($limit: Int!, $page: Int!, $delete_flag: Int!` + user_type + `){kyc(count:$limit,page:$page,delete_flag:$delete_flag` + user_value + `){data{id,status}paginatorInfo{total,perPage,hasMorePages,currentPage,firstItem}}}`),
            limitObject,
            {
                pagination: 'kyc',
                scope: 'kyc',
                fetchPolicy: 'no-cache',
                subscribe: true,
                onChange: function () {
                    if (this.graphQlKYCQuery && this.graphQlKYCQuery.hasOwnProperty('data') && this.graphQlKYCQuery.data) {
                        this.graphQlKYCQueryResponse(list_view, permissionData, form_data);
                        if (this.role != "superAdmin" && this.graphQlKYCQuery.data.data.length > 0 && this.graphQlKYCQuery.data.data[0].status == "Pending" || this.graphQlKYCQuery.data.data[0].status =='Rejected') {
                            this.httpService.setModelName({ 'modelName': 'kyc', 'path': '/forms/edit' });
                            this.httpService.setFormDataToEdit({
                                id: this.graphQlKYCQuery.data.data[0].id,
                                readOnly: true,
                                model_name: 'kyc'
                            });
                            this.router.navigate(['/forms/edit']);
                        } else {
                            if (this.query_subscribe) {
                                this.onRefreshNavigation(this.queryParam);
                            } else {
                                this.router.navigate(['/dashboard']);
                            }
                        }
                    }
                }.bind(this)
                //'cache-first' | 'cache-and-network' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
            });
    }

    graphQlKYCQueryResponse(list_view, permissionData, form_data) {
        this.httpService.setAllFormData(form_data);
        var setHeader: any = [];
        var modelList = [];
        permissionData.forEach(element => {
            let activeModelName = (element.name).split("-")[0];
            var data_query = [];
            var actionList = [];
            var title = "";
            if (!modelList.includes(activeModelName)) {
                // SET FORM JSON AND TYPE ( HEADER / SEARCH )
                if (list_view.length > 0) {
                    list_view.forEach(models_list => {
                        if (activeModelName == models_list.model_name) {
                            let dataquery = {}
                            dataquery["query"] = models_list.query_field;
                            dataquery["type"] = models_list.type;
                            data_query.push(dataquery);
                            title = models_list.title
                        }
                    });
                }
                // SET ACTIONS ( VIEW / CREATE )
                permissionData.filter(item => {
                    if ((item.name).split("-")[0] == activeModelName) {
                        actionList.push((item.name).split("-")[1]);
                    }
                });
                // SET SUBMENU
                var permissionArr = [];
                actionList.forEach(action => {
                    list_view.forEach(models_list => {
                        if (activeModelName == models_list.model_name) {
                            if (models_list.type == 'header') {
                                permissionArr.push({
                                    'permission': action,
                                    'model_name': activeModelName,
                                    'list_query_key': models_list.query_field
                                });
                            }
                        }
                    });
                });
                // SET SIDEBAR MENU
                if (data_query != []) {
                    setHeader.push({ path: '', user_id:this.user_id, 'permissionData': actionList, data_query: data_query, model: activeModelName, title: title, icon: 'ft-droplet', class: 'has-sub', isClicked: false, badge: '', badgeClass: '', isExternalLink: false, submenu: this.getChildrenList(permissionArr) });
                    !modelList.includes(activeModelName) && modelList.push(activeModelName);
                }
            }
        });
        this.httpService.setHeaderData(setHeader);
    }

    initAllConstructorFunc() {
        let token = this.authService.getToken();
        if (token == "") {
            this.showLoader = false;
            this.router.navigate(['pages/login']);
        } else {
            let userData = this.authService.getUserData();
            this.httpService.setGlobalConstants(userData);
        }

        this.httpService.getGlobalConstants().subscribe(data => {
            if (data.hasOwnProperty('access_token')) {
                !data['reset'] && this.httpService.greetToasterMsg("success", "Welcome!", data['user'].name + ' ');
                this.user_id = data['user'].id;
                this.showLoader = false;
                this.getDataForListingMenu();
                this.isQueryFired = true;
            }
        });

    }

    onRefreshNavigation(event) {
        // do something...           
        let array_of_url = event.url.split('?');
        let path = event.url.split('?')[0];
        let model_string = event.url.split('?')[1];
        let inst = model_string.split('&');
        var model = '';
        var queryParams = {};
        for (let i = 0; i < inst.length; i++) {
            if (inst[i].split('=')[0] == 'model') {
                model = inst[i].split('=')[1];
                this.httpService.setModelName({ 'modelName': inst[i].split('=')[1], 'path': path });
                queryParams['model'] = model;
            }
            if (path.includes('edit') && inst[i].split('=')[0] == 'id' && model != '') {
                this.httpService.setFormDataToEdit({
                    id: inst[i].split('=')[1],
                    readOnly: false,
                    model_name: model
                });
                queryParams['id'] = inst[i].split('=')[1];
            }
        }

        // var model_name = model_string.split('=')[1]; 
        // this.httpService.setModelName({'modelName':model_name,'path':path});
        this.query_subscribe = true;
        this.router.navigate([path], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
            // preserve the existing query params in the route
            skipLocationChange: false
            // do not trigger navigation
        });

    }






}
