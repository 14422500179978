import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { AuthService } from '../auth/auth.service';
import { LawfirmApiServiceService } from '../services/lawfirm-api-service.service';
import { HttpService } from '../services/http.service';
import { environment } from '../../../environments/environment';
import { Buoy, Query } from '@buoy/client';
import gql from 'graphql-tag';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  graphQlQuery: Query;
  public isCollapsed = true;
  activePageLimit = environment.page_limit;
  currentActivePage: number = 1;
  layoutSub: Subscription;
  @Output() toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private authService: AuthService,
    private buoy: Buoy,
    private httpService: HttpService
  ) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      });
      var limitObject   = {};
      limitObject = {
        limit: this.activePageLimit,
        page:  this.currentActivePage,
        is_read: 0
      }  

      this.graphQlQuery = this.buoy.query(
        gql(`query notification($limit: Int!, $page: Int!, $is_read: Int!){notification(count:$limit,page:$page,is_read:$is_read){data{id,summary,title}paginatorInfo{total,perPage,hasMorePages,currentPage,firstItem}}}`),
        limitObject,
        {
            pagination: 'notification',
            scope:      'notification.data',
            fetchPolicy: 'no-cache',
            subscribe:true,
            onChange:function(event){  
            
            }.bind(this)
        })
    // this.graphQlQuery = this.buoy.query(
    //   gql(`query {notifications(count:10,page:1){data{summary}}}`),
    //   {
    //   },
    //   {
    //     scope: 'notifications.data',
    //     fetchPolicy: 'cache-first',
    //     //'cache-first' | 'cache-and-network' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
    //   });

  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);

    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  logoutClickHandler() {
    var callback = function () {
      this.authService.logout();
    }.bind(this);
    this.httpService.triggerSweetAlert('warning', 'Are you sure ?', '', true, callback)

  }
}
