import { Router,RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { debug } from 'util';

@Injectable()
export class AuthService {
  token: string;

  constructor(
    private router :Router,
  ) { }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    this.token = null;
    localStorage.removeItem('userConstants');
    this.router.navigate(["pages/login"])
  }

  getToken() {
   
    let userConstants = localStorage.getItem('userConstants');
    if (userConstants != null) {
      let userData = JSON.parse(atob(userConstants));

      this.token = (userData.hasOwnProperty('access_token')) ? userData.access_token : "";
    } else {
      this.token = "";
    }

    return this.token;
  }

  getUserData(){
    let userConstants = localStorage.getItem('userConstants');
    return (userConstants != null)  ? JSON.parse(atob(userConstants)) : null;
   }

  isAuthenticated() {
    let hasToken = this.getToken();
    
   if(hasToken){
     return true;
   }else{
     
     return false;
   }

  }
}
