import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class LawfirmApiServiceService {

	apiLink: string = "http://lawfirmv2.oneleap.in/graphql?query=";

	private headersCache = new BehaviorSubject<Array<object>>([]);
	private loginDataCache = new BehaviorSubject<Array<object>>([]);
	private globalConstantValues = new BehaviorSubject<Array<object>>([]);
	private activeModelName = new BehaviorSubject<String>("");
	private headerNotifications = new BehaviorSubject<Array<object>>([]);
	private customSearchParam = new BehaviorSubject<Array<object>>([]);
	private customColumnFilter = new BehaviorSubject<object>({});
	private usersInfo = new BehaviorSubject<object>({});
	private isExportCSVTriggerd = new BehaviorSubject<Boolean>(false);
	private uiThemeCustomizer = new BehaviorSubject<object>({});
	private jsonFormData = new BehaviorSubject<object>({});

	constructor(
		private http: HttpClient,
		public toastr: ToastrService,
	) { }

	getDataApi(url: string): Observable<any> {
		// return this.http.get(this.apiLink + url);
		return this.http.get(url);
	}

	loginUser(userData: any, url): Observable<any> {
		return this.http.post(this.apiLink + url, userData);
	}

	setUserLoginData(userData: any) {
		this.loginDataCache.next(userData);
	}

	getUserLoginData() {
		return this.loginDataCache.asObservable();
	}

	setHeaderData(headerData: any) {
		this.headersCache.next(headerData);
	}

	getHeaderData(): Observable<any> {
		return this.headersCache.asObservable();
	}

	setActiveModelName(active_model: any) {
		this.activeModelName.next(active_model);
	}

	getActiveModelName() {
		return this.activeModelName.asObservable();
	}

	setGlobalConstants(globalConstants) {
		this.globalConstantValues.next(globalConstants);
	}

	getGlobalConstants() {
		return this.globalConstantValues.asObservable();
	}

	getHeaderNotifications() {
		return this.headerNotifications.asObservable();
	}

	setHeaderNotifications(data: any) {
		this.headerNotifications.next(data);
	}

	setCustomSearchParams(data: any) {
		this.customSearchParam.next(data);
	}

	getCustomSearchParams() {
		return this.customSearchParam.asObservable();
	}

	setCustomColumnFilter(data: any) {
		this.customColumnFilter.next(data);
	}

	getCustomColumnFilter() {
		return this.customColumnFilter.asObservable();
	}

	setUserInfo(data: any) {
		this.usersInfo.next(data);
	}

	getUsersInfo() {
		return this.usersInfo.asObservable();
	}

	setisExportCSVTriggerd(data: boolean) {
		this.isExportCSVTriggerd.next(data);
	}

	getisExportCSVTriggerd() {
		return this.isExportCSVTriggerd.asObservable();
	}

	setUIThemeCustomizer(data: any) {
		this.uiThemeCustomizer.next(data);
	}
 
	getUIThemeCustomizer() {
		return this.uiThemeCustomizer.asObservable();
	}

	getActiveListValues(dataObject, headerList, type) {
		return headerList.filter(item => {
			if (item.model == dataObject && item.type == type) {
				return item
			}
		});
	}

	setJSONformData(data:any){
		this.jsonFormData.next(data);
	}

	getJSONformData(){
		return this.jsonFormData.asObservable();
	}






	greetToasterMsg(type, title, message) {
		setTimeout(function(){
			eval("this.toastr." + type + "(message, title , { closeButton: true })");	
		}.bind(this));
	}

	triggerSweetAlert(type, title, message, isConfirm, callbackfunction) {
		if (!isConfirm) {
			swal(title, message, type);
		} else {
			swal({
				title: title,
				text: message,
				type: type,
				showCancelButton: true,
				confirmButtonColor: '#0CC27E',
				cancelButtonColor: '#FF586B',
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				confirmButtonClass: 'btn btn-success btn-raised mr-5',
				cancelButtonClass: 'btn btn-danger btn-raised',
				buttonsStyling: false
			}).then(function (event) {
				event.value && callbackfunction();
			});
		}
	}


}
