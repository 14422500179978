import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { Buoy, Query } from '@buoy/client';
import { BehaviorSubject } from 'rxjs';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from './http.service';

import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  graphQlQuery: Query;
  listData: any = {};
  listingQuery:Observable<any> = new BehaviorSubject<Array<object>>([]);
  private mutateQueryData = new BehaviorSubject<Array<object>>([]);

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private toastr: ToastrService, 
    private httpservice: HttpService,
    private spinner: NgxSpinnerService,
    private buoy: Buoy
  ) { }

  getListingQueryData(model_name,data_type,data_assign,values,limitObject,fetchPolicy,subscribe = false) {
   // this.spinner.show();
    this.httpservice.setshowLoader(true);

    (Array.isArray(data_type)) && (data_type = data_type.filter(Boolean).join(","));
    (Array.isArray(data_assign)) && (data_assign = data_assign.filter(Boolean).join(","));
    (Array.isArray(values)) && (values = values.filter(Boolean));

    this.graphQlQuery = this.buoy.query(
      gql(`query ` + model_name + `($limit: Int!, $page: Int!, $delete_flag: Int! ,`+data_type+`){` + model_name + `(count:$limit,page:$page,delete_flag:$delete_flag,`+data_assign+`){data{` + values + `}paginatorInfo{total,perPage,hasMorePages,currentPage,firstItem}}}`),
      limitObject,
      {
      pagination: model_name,
      scope:      model_name,
      fetchPolicy: fetchPolicy, //'cache-first' | 'cache-and-network' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
      subscribe:true,
      onChange:function(){
         // this.spinner.hide();
          this.httpservice.setshowLoader(false);
          this.listingQuery.next(this.graphQlQuery);
      }.bind(this)
    });
  }

  navigateWithQuery(path, params) {
    this.router.navigate([path], {
      relativeTo: this.route,
      queryParams: params,
     // queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false // do not trigger navigation
    });
  }

  // updateBookingStatus(){
  //   let mutation_name = ''
  //   this.buoy.mutate(gql(`mutation ` + mutation_name + `(` + optionString.join(',') + `){` + mutation_name + `(` + queryString.join(',') + `){` + returnString.join(',') + `}}`),
  //     // Variables
  //     Variables
  //     ,
  //     // Options
  //     {
  //       scope: mutation_name,
  //       // fetchPolicy: 'no-cache',
  //     }
  //   ).toPromise()
  //     .then(
  //       (success) => {
  //         success['instance'] = requestFunc;
  //         this.setMutateData(success);
  //         callback();
  //       },
  //       (error) => {
  //         console.log('Something went wrong:', error);
  //       }
  //   );
  // }

  // commonMutateQuery(Variables, optionString, queryString, mutation_name, returnString, callback, requestFunc){
  //   this.buoy.mutate(gql(`mutation ` + mutation_name + `(` + optionString.join(',') + `){` + mutation_name + `(` + queryString.join(',') + `){` + returnString.join(',') + `}}`),
  //     // Variables
  //     Variables
  //     ,
  //     // Options
  //     {
  //       scope: mutation_name,
  //       // fetchPolicy: 'no-cache',
  //     }
  //   ).toPromise()
  //     .then(
  //       (success) => {
  //         success['instance'] = requestFunc;
  //         this.setMutateData(success);
  //         callback();
  //       },
  //       (error) => {
  //         console.log('Something went wrong:', error);
  //       }
  //   );
  // }

  // setMutateData(data: any) {
  //   return this.mutateQueryData.next(data);
  // }

  // getMutateData() {
  //   return this.mutateQueryData.asObservable();
  // }
  

  // Use if variable is private to make it public
  // public returndata(): Observable <any> {
  //   return this.listingQuery;
  // }

  // public returndataObs(){
  //   return this.listingQuery;
  // }
}
