import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../shared/auth/auth.service';


@Injectable() 
export class HttpConfigInterceptor implements HttpInterceptor { 
	token:string;
	constructor(
        private authService : AuthService
    ){
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const token: string = localStorage.getItem('token');
        this.token = this.authService.getToken();
        if (this.token != "") {
            request = request.clone(
                { headers: request.headers.set('Authorization', 'Bearer ' + this.token)
            });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse) {
                //     console.log('event--->>>', event);
                // }
                return event;
            }));
    }

    // catchError((error: HttpErrorResponse) => {
    //             let data = {};
    //             data = {
    //                 reason: error && error.error.reason ? error.error.reason : '',
    //                 status: error.status
    //             };
    //             this.errorDialogService.openDialog(data);
    //             return throwError(error);
    //         })

}



