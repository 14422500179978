import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
//mport { map } from 'rxjs/operators';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { Buoy, Query } from '@buoy/client';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import swal from 'sweetalert2';
import gql from 'graphql-tag';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  Apiurl = environment.Apiurl;
  ApiMidUrl = environment.ApiMidUrl;
  objectdata: any = [];
  data: any;
  bookingdata: any;
  storeponumber: any;
  bookdetail: any;
  store_shipo: any;
  shipdata: any;
  podata: any;
  public graphQlQuery: Query;
  private activeRefName = new BehaviorSubject<String>("");
  private headersCache = new BehaviorSubject<Array<object>>([]);
  public shiprateDataToShow = new BehaviorSubject<Array<object>>([]);
  public modelname = new BehaviorSubject<object>({});
  private setSqlQueryData = new BehaviorSubject("");
  private customColumnFilter = new BehaviorSubject<object>({});
  private uiThemeCustomizer = new BehaviorSubject<object>({});
  private isExportCSVTriggerd = new BehaviorSubject<Boolean>(false);
  private customSearchParam = new BehaviorSubject<Array<object>>([]);
  private jsonFormData = new BehaviorSubject<object>({});
  private company_reg_id = new BehaviorSubject<object>({});
  private loginDataCache = new BehaviorSubject<Array<object>>([]);
  private jsonFormReadonly = new BehaviorSubject<Boolean>(false);
  private allformData = new BehaviorSubject<Array<object>>([]);
  private globalConstantValues = new BehaviorSubject<Array<object>>([]);
  private formComponentData = new BehaviorSubject<object>({});
  private DisplayColumn = new BehaviorSubject<Array<object>>([]);
  private operatorsAssigned = new BehaviorSubject<Array<object>>([]);
  private roleName = new BehaviorSubject("");
  private usersInfo = new BehaviorSubject<object>({});
  private successdata = new BehaviorSubject<Array<object>>([]);
  private updatedata = new BehaviorSubject<Array<object>>([]);
  private kycComment = new BehaviorSubject<Array<object>>([]);
  private bookingidtoshowsummary = new BehaviorSubject<Array<object>>([]);
  quotations_details:Observable<any> = new BehaviorSubject<Array<object>>([]);
  private tierDetail = new BehaviorSubject<Array<object>>([]);
  private showLoader = new BehaviorSubject<Boolean>(false);
  private refreshForm = new BehaviorSubject<Boolean>(false);
  private modelFormValue = new BehaviorSubject<object>({});
  private mineOthersFilter = new BehaviorSubject<object>({});
  private selectedRows = new BehaviorSubject<Array<object>>([]);


  private ListViewData = new BehaviorSubject<Array<object>>([]);


  public resultData: Query;

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private http: HttpClient, private httpClient: HttpClient, private buoy: Buoy, private spinner: NgxSpinnerService,) { }

  store_booking_data(objectdata) {
    this.bookingdata = { 'query': 'mutation{CreateBooking(over_agent_name: "' + objectdata.over_agent_name + '",over_agent_address: "' + objectdata.over_agent_address + '",consignee: "' + objectdata.consignee + '",type_shipment: "' + objectdata.type_shipment + '",incoterm: "' + objectdata.incoterm + '",shipper_name: "' + objectdata.shipper_name + '",shipper_contact: "' + objectdata.shipper_contact + '",pi_ord_no: "' + objectdata.pi_ord_no + '",style: "' + objectdata.style + '",volume: "' + objectdata.volume + '",pickup_address: "' + objectdata.pickup_address + '",por: "' + objectdata.por + '",pol: "' + objectdata.pol + '",pod: "' + objectdata.pod + '",fpd: "' + objectdata.fpd + '",oncarriage_del_mode: "' + objectdata.oncarriage_del_mode + '",delivery_address: "' + objectdata.delivery_address + '",shipping_line: "' + objectdata.shipping_line + '",contract_owner: "' + objectdata.contract_owner + '",agree_buy_rate: "' + objectdata.agree_buy_rate + '",agree_free_time_pod: "' + objectdata.agree_free_time_pod + '",mbl_freight_term: "' + objectdata.mbl_freight_term + '",hbl_freight_term: "' + objectdata.hbl_freight_term + '",profit_share_orhandling: "' + objectdata.profit_share_orhandling + '",activity_code: "' + objectdata.activity_code + '",pty_code_party: "' + objectdata.pty_code_party + '" ,shippercode: "' + objectdata.selected_shippercode + '"){id}}', 'operationName': null, 'variables': {} };
    return new Promise((resolve, reject) => {
      this.http.post(this.Apiurl, this.bookingdata)
        .subscribe(res => {
          resolve(res);
          //  alert(JSON.stringify(res));
        }, (err) => {
          //  //alert(JSON.stringify(err));
          reject(err);
        });
    });
  }

  get_booking_data() {
    this.http.get('https://cshipmid.oneleap.in/graphql?query={bookingdetails(code: "PACA0018" ) {bd_bkg_number,shipper,bd_po_number}}').map(res => res).subscribe(data => {
      return data;
    });
  }

  buyerdetails(id) {
    this.http.get(this.Apiurl + '?query={bookingdetails(code: ' + id + ' ) {bd_bkg_number,shipper,bd_po_number}}').map(res => res).subscribe(data => {
      return data;
    });
  }

  store_shippo(shipdata, poids) {
    this.store_shipo = { 'query': 'mutation{CreateShippo(po_id: "' + poids + '", po_qty: "' + shipdata.po_qty + '", assign_port: "' + shipdata.assign_port + '", assign_forwarder: "' + shipdata.assign_forwarder + '" ){id}}', 'operationName': null, 'variables': {} };
    return new Promise((resolve, reject) => {
      this.http.post(this.Apiurl, this.store_shipo)
        .subscribe(res => {
          resolve(res);
          // alert("sucess data" + JSON.stringify(res));
        }, (err) => {
          // alert("store shipper error - " + JSON.stringify(err));
          reject(err);
        });
    });
  }

  register_user(userdata) {
    let query = { 'query': 'mutation{CreateCompany_registration(cmp_name:"' + userdata.cmp_name + '",logo_file:"' + userdata.logo_file + '",year_business_started:"' + userdata.year_business_started + '",telephone:' + userdata.telephone + ',owner_name:"' + userdata.owner_name + '",fax:' + userdata.fax + ',add_of_head_offc:"' + userdata.add_of_head_offc + '",email_address:"' + userdata.email_address + '",country:"' + userdata.country + '",website:"' + userdata.website + '",services:"' + userdata.services + '",iata:"' + userdata.iata + '",registration_no:"' + userdata.registration_no + '",airfreight_percent:' + userdata.airfreight_percent + ',seafreight_percent:' + userdata.seafreight_percent + ',roadfreight_percent:' + userdata.roadfreight_percent + ',inbound_percent:' + userdata.inbound_percent + ',outbound_percent:' + userdata.outbound_percent + ',list_of_nat_compy_covers:"' + userdata.list_of_nat_compy_covers + '",list_of_nat_seaport_compy_covers:"' + userdata.list_of_nat_seaport_compy_covers + '",ware_house_size:' + userdata.ware_house_size + ',no_of_emp:' + userdata.no_of_emp + ',own_vehicle:"' + userdata.own_vehicle + '",vehicle_details:"' + userdata.vehicle_details + '",branch_offc:"' + userdata.branch_offc + '",list_city_of_brnch_off:"' + userdata.list_city_of_brnch_off + '",profnl_liable_insurance:"' + userdata.profnl_liable_insurance + '",insurance_certificate:"' + userdata.insurance_certificate + '",licenced_custom_broker:"' + userdata.licenced_custom_broker + '",licenced_certificate:"' + userdata.licenced_certificate + '",how_did_u_find_us:"' + userdata.how_did_u_find_us + '",login_first_name:"' + userdata.login_first_name + '",login_last_name:"' + userdata.login_last_name + '",login_chose_username:"' + userdata.login_chose_username + '",login_chose_password:"' + userdata.login_chose_password + '"){id}}' };
    return this.httpClient.post<any>(this.Apiurl, query);
  }

  upload_file(fileData): Observable<any> {

    let fileN = new Blob(fileData, { type: 'text/plain' });
    let newHeaders = new HttpHeaders();
    newHeaders.append('Content-Type', 'multipart/form-data');
    // newHeaders.append('processData',false);

    let query = { 'query': 'mutation($file: Upload!){myUploadMutation(file: $file , title:"hello")}', context: { useMultipart: true }, 'variables': { "file": fileData[0] } };
    return this.httpClient.post<any>(this.Apiurl, query, { headers: newHeaders });

  }

  store_po_number(podata) {
    let query = this.Apiurl + '?query=mutation{CreatePonumber(name:"' + podata.name + '",po_number:"' + podata.po_number + '",shipper: "' + podata.shipper + '",style_design:"' + podata.style_design + '",colour:"' + podata.colour + '",size:"' + podata.size + '",thickness:"' + podata.thickness + '",sqm:"' + podata.sqm + '",no_objects:"' + podata.no_objects + '"){id}}';
    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          // alert("response error" +JSON.stringify(err));
          reject(err);
        });
    });
  }

  //get report of buyer
  get_buying_report(name) {
    let query = this.Apiurl + '?query={buyer_records(Buyer: "' + name + '" ) {AgentRefNo,OverseasAgentLogin,OverseasAgent, BuyerLogin,Buyer,ConsigneeRefNo,OrderRefNo,CsNo,Shipper,InvoiceNo,BkgDate,ReadyDate,BookingStatus,Origin,POL,POD,FinalDestination,Mode,Carrier,CarrierBkgNo,VesselName,Voyage,ETD,ContainerNo,Size,Type,SealNo,HBL,MBL,PreAlertDate}}';
    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          // //alert(JSON.stringify(err));
          reject(err);
        });
    });
  }

  //get report of buyer
  get_agents_report(name) {

    let query = this.Apiurl + '?query={agent_records(OverseasAgentLogin: "' + name + '" ) {AgentRefNo,OverseasAgentLogin,OverseasAgent, BuyerLogin,Buyer,ConsigneeRefNo,OrderRefNo,CsNo,Shipper,InvoiceNo,BkgDate,ReadyDate,BookingStatus,Origin,POL,POD,FinalDestination,Mode,Carrier,CarrierBkgNo,VesselName,Voyage,ETD,ContainerNo,Size,Type,SealNo,HBL,MBL,PreAlertDate}}';

    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          // //alert(JSON.stringify(err));
          reject(err);
        });
    });
  }

  //get buyer pos list
  get_buyer_polist() {

    let query = this.Apiurl + '?query={buyer_polist{name,po_number,shipper,style_design,colour,size,thickness,sqm,no_objects}}';

    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          //  //alert(JSON.stringify(err));
          reject(err);
        });
    });
  }

  get_shipped_polist() {

    let query = this.Apiurl + '?query={shipped_po{id,po_id,po_qty,assign_port,assign_forwarder,ponumber {po_number,shipper} }}';
    //  alert(query + "query new");
    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          //  alert(JSON.stringify(res));
        }, (err) => {
          //  //alert(JSON.stringify(err));
          reject(err);
        });
    });

  }

  get_shipper_list() {

    let query = this.Apiurl + '?query={shipper_list{Shipper}}';

    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          //alert(JSON.stringify(err));
          reject(err);
        });
    });


  }


  get_agent_list() {

    let query = this.Apiurl + '?query={agents{OverseasAgentLogin}}';

    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          //alert(JSON.stringify(err));
          reject(err);
        });
    });
  }


  get_buyer_polist_new() {
    let query = this.Apiurl + '?query={buyer_polist{id,name,po_number,shipper,style_design,colour,size,thickness,sqm,no_objects}}';
    let data = this.http.get(query);
    return data;
  }


  get_buyer_list() {

    // let query = this.Apiurl + '?query={buyer{Buyer}}';
    let query = this.Apiurl + '?query={buyer_polist{id,name,po_number,shipper,style_design,colour,size,thickness,sqm,no_objects}}';

    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          //alert(JSON.stringify(err));
          reject(err);
        });
    });


  }


  get_ports() {

    let query = this.ApiMidUrl + '?query={ports {pot_port_code,pot_name,pot_country_code}}';

    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          //alert(JSON.stringify(err));
          reject(err);
        });
    });


  }

  get_chosen_po(poids) {
    let query = this.Apiurl + '?query={chosen_po(poids:[' + poids + ']){ id,name,po_number,sqm,no_objects}}';
    return new Promise((resolve, reject) => {
      this.http.get(query)
        .subscribe(res => {
          resolve(res);
          // alert(JSON.stringify(res));
        }, (err) => {
          //alert(JSON.stringify(err));
          reject(err);
        });
    });
  }

  //get buyer pos list
  get_shipping_info(): Observable<any> {
    let query = { 'query': '{shipratemaster{shipping_line, commodity, por, pol, pod, container, max_weight, ebs, rate } }' };
    return this.httpClient.post<any>(this.Apiurl, query);
  }

  get_rate(shippingData): Observable<any> {
    let query = { 'query': '{shipratemaster_rate(shipping_line:"' + shippingData.shipping_line + '", commodity: "' + shippingData.commodity + '", por:"' + shippingData.por + '", pol: "' + shippingData.pol + '", pod: "' + shippingData.pod + '", container: "' + shippingData.container + '", max_weight:"' + shippingData.max_weight + '"){ ebs, rate, total, ens, detention, demurrages, port_storage }}' };
    return this.httpClient.post<any>(this.Apiurl, query);
  }
  get_country_list(): Observable<any> {
    let query = { 'query': '{country_list{iso,name,numcode}}' };
    return this.httpClient.post<any>(this.Apiurl, query);
  }
  save_shiprate(shiprateData) {
    this.shiprateDataToShow.next(shiprateData);
  }


  setModelName(mode_name) {
    this.modelname.next(mode_name);
  }

  getModelName() {
    return this.modelname.asObservable();
  }

  getModelData(model_name): Observable<any> {
    let query = this.Apiurl + '?query={' + model_name + '{' + this.getModelValues(model_name) + '}}';
    let data = this.http.get(query);
    return data;
  }

  getModelValues(model_name) {
    let data = {
      shipper_list: ['Shipper'],
      company_registration_edit: ['id', 'cmp_name', 'subSelection-logo_file{name,size}', 'year_business_started', 'telephone', 'owner_name', 'fax', 'add_of_head_offc', 'email_address', 'country', 'website', 'services', 'iata', 'registration_no', 'airfreight_percent', 'seafreight_percent', 'roadfreight_percent', 'inbound_percent', 'outbound_percent', 'list_of_nat_compy_covers', 'list_of_nat_seaport_compy_covers', 'ware_house_size', 'no_of_emp', 'own_vehicle', 'vehicle_details', 'branch_offc', 'list_city_of_brnch_off', 'profnl_liable_insurance', 'insurance_certificate', 'licenced_custom_broker', 'licenced_certificate', 'how_did_u_find_us', 'login_first_name', 'login_chose_username', 'login_last_name', 'login_chose_password', 'accept_terms_condition'],
    };
    return data[model_name];
  }


  setDisplayColumns(data) {
    this.DisplayColumn.next(data);
  }

  getDisplayColumns() {
    return this.DisplayColumn.asObservable();

  }


  setHeaderData(headerData: any) {
    this.headersCache.next(headerData);
  }

  getHeaderData(): Observable<any> {
    return this.headersCache.asObservable();
  }

  setCustomColumnFilter(data: any) {
    this.customColumnFilter.next(data);
  }

  getCustomColumnFilter() {
    return this.customColumnFilter.asObservable();
  }

  setUIThemeCustomizer(data: any) {
    this.uiThemeCustomizer.next(data);
  }

  getUIThemeCustomizer() {
    return this.uiThemeCustomizer.asObservable();
  }

  setisExportCSVTriggerd(data: boolean) {
    this.isExportCSVTriggerd.next(data);
  }

  getisExportCSVTriggerd() {
    return this.isExportCSVTriggerd.asObservable();
  }


  setGlobalConstants(globalConstants) {
    this.globalConstantValues.next(globalConstants);
  }

  getGlobalConstants() {
    return this.globalConstantValues.asObservable();
  }

  setJSONformData(data: any) {
    this.jsonFormData.next(data);
  }

  getJSONformData() {
    return this.jsonFormData.asObservable();
  }

  getSaveFormData() {
    return this.successdata.asObservable();
  }

  setSaveFormData(data: any) {
    return this.successdata.next(data);
  }

  getUpdateFormData() {
    return this.updatedata.asObservable();
  }

  setUpdateFormData(data: any) {
    return this.updatedata.next(data);
  }

  // mutatiop query for data save
  saveFormData(Variables, optionString, queryString, mutation_name, returnString, callback, requestFunc) {
    this.spinner.show();
    this.buoy.mutate(gql(`mutation ` + mutation_name + `(` + optionString.join(',') + `){` + mutation_name + `(` + queryString.join(',') + `){` + returnString.join(',') + `}}`),
      // Variables
      Variables
      ,
      // Options
      {
        scope: mutation_name,
        // fetchPolicy: 'no-cache',
      }
    ).toPromise()
      .then(
        (success) => {
          success['instance'] = requestFunc;
          this.spinner.hide();
          this.setSaveFormData(success);
          callback();
        },
        (error) => {
          console.log('Something went wrong:', error);
        }
      );
  }


  updateFormData(id, Variables, optionString, queryString, modelName, data) {
    this.spinner.show();
    this.buoy.mutate(gql(`mutation formPostUpdate($id:ID!,$model_name:String,$data:String){formPostUpdate(id:$id,model_name:$model_name,data:$data){id}}`),
      // Variables
      {
        id: id,
        model_name: modelName,
        data: data
      }
      ,
      // Options
      {
        scope: 'formPostUpdate'
      }
    ).toPromise()
      .then(
        (success) => {
          this.setUpdateFormData(success);
          this.spinner.hide();
        },
        (error) => {
          console.log('Something went wrong:', error);
        }
      );
  }

  setCustomSearchParams(data: any) {
    this.customSearchParam.next(data);
  }

  getCustomSearchParams() {
    return this.customSearchParam.asObservable();
  }

  getData(model_name, values) {
    this.graphQlQuery = this.buoy.query(
      gql(`query ` + model_name + `{` + model_name + `{data{` + values + `}}}`),
      {
        scope: model_name,
        fetchPolicy: 'no-cache',
        //'cache-first' | 'cache-and-network' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
      });
  }

  setAllFormData(data) {
    this.allformData.next(data);
  }

  getAllFormData() {
    return this.allformData.asObservable();
  }

  // deleteFormData(id){
  //   console.log(`mutation updateCompanyRegistration($id:ID!,$delete_flag:Int!){updateCompanyRegistration(id:$id,delete_flag:$delete_flag){id}}`);
  //   let data = this.buoy.mutate(gql(`mutation updateCompanyRegistration($id:ID!,$delete_flag:Int!){updateCompanyRegistration(id:$id,delete_flag:$delete_flag){id}}`),
  //   // Variables
  //   {
  //     id: id,
  //     delete_flag: 1
  //   },
  //   // Options
  //   { scope: 'updateCompanyRegistration' }
  //   ).toPromise()
  //   .then(
  //       (success) => {
  //           alert('Data Deleted Successfully');
  //       },
  //       (error) => {
  //       }
  //   );
  // }

  setFormReadOnly(data) {
    this.jsonFormReadonly.next(data);
  }

  getFormReadonly() {
    return this.jsonFormReadonly.asObservable();
  }
  setFormDataToEdit(data) {
    this.company_reg_id.next(data);
  }
  getFormDataToEdit() {
    return this.company_reg_id.asObservable();
  }

  triggerSweetAlert(type, title, message, isConfirm, callbackfunction) {
    if (!isConfirm) {
      swal(title, message, type);
    } else {
      swal({
        title: title,
        text: message,
        type: type,
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonClass: 'btn btn-success btn-raised mr-5',
        cancelButtonClass: 'btn btn-danger btn-raised',
        buttonsStyling: false
      }).then(function (event) {
        event.value && callbackfunction();
      });
    }
  }

  greetToasterMsg(type, title, message) {
    setTimeout(function () {
      eval("this.toastr." + type + "(message, title , { closeButton: true })");
    }.bind(this));
  }

  setFormComponent(formComponent) {
    return this.formComponentData.next(formComponent);
  }

  getFormComponent() {
    return this.formComponentData.asObservable();
  }

  getFormDetails(id): Observable<any> {

    let company_registration_data = ['id', 'cmp_name', 'logo_file{id,name,originalName,size,storage,type,url}', 'year_business_started', 'telephone', 'owner_name', 'fax', 'add_of_head_offc', 'email_address', 'country', 'state', 'city', 'website', 'services', 'iata', 'registration_no', 'airfreight_percent', 'seafreight_percent', 'roadfreight_percent', 'inbound_percent', 'outbound_percent', 'list_of_nat_compy_covers', 'list_of_nat_seaport_compy_covers', 'ware_house_size', 'no_of_emp', 'own_vehicle', 'vehicle_details', 'branch_offc', 'list_city_of_brnch_off', 'profnl_liable_insurance', 'insurance_certificate{id,name,originalName,size,storage,type,url}', 'licenced_custom_broker', 'licenced_certificate{id,name,originalName,size,storage,type,url}', 'how_did_u_find_us', 'login_first_name', 'login_chose_username', 'login_last_name', 'login_chose_password', 'accept_terms_condition'];
    let query = { 'query': '{company_registration_edit(id:' + id + '){' + company_registration_data.join() + '}}' };
    return this.httpClient.post<any>(this.Apiurl, query);
    // this.graphQlQuery = this.buoy.query(
    //   gql(`query company_registration($id: Int!){company_registration(id:$id){data{` + company_registration_data + `}}}`),
    //   {
    //     id: id,
    //   },
    //   {
    //     scope: 'company_registration'
    //   }
    // );
    // return this.graphQlQuery;
  }

  subSelectionObject(subSelectionData) {
    let keys = Object.keys(subSelectionData);
    var subSelectionDataObj = [];
    for (var t = 0; t < keys.length; t++) {
      subSelectionDataObj.push(',' + keys[t] + `{` + subSelectionData[keys[t]] + `}`);
    }
    return subSelectionDataObj;
  }

  getQueryColums(items, type) {
    var values = items[0].data_query.filter(item => {
      if (item.type == type) {
        return item;
      }
    });
    let returnObj = [];
    // if(values.length > 0){
      returnObj['query'] = (values.length > 0) ? Object.keys(JSON.parse(values[0].query)) : [];
      returnObj['display'] = (values.length > 0) ? Object.values(JSON.parse(values[0].query)) : []; 
    // }

    return returnObj;
  }

  setRole(roleName) {
    return this.roleName.next(roleName);
  }

  getRole() {
    return this.roleName.asObservable();
  }

  setUserLoginData(userData: any) {
    this.loginDataCache.next(userData);
  }

  getUserLoginData() {
    return this.loginDataCache.asObservable();
  }

  getUsersInfo() {
    return this.usersInfo.asObservable();
  }

  setUserInfo(data: any) {
    this.usersInfo.next(data);
  }

  setOperatorsAssigned(data: any) {
    this.operatorsAssigned.next(data);
  }
  getOperatorsAssigned() {
    return this.operatorsAssigned.asObservable();
  }

  setkycComment(data: any) {
    this.kycComment.next(data);
  }

  getkycComment() {
    return this.kycComment.asObservable();
  }

  setBookingId(data: any) {
    this.bookingidtoshowsummary.next(data);
  }

  getBookingId() {
    return this.bookingidtoshowsummary.asObservable();
  }

  getQuotationsDetails(model_name,quotation_number,chk_quotations_hidden_id,callback){ 
    let data_type = ", $quotation_number: String, $chk_quotations_hidden_id: String, $groupBy: String";
    let data_assign = ",quotation_number:$quotation_number,chk_quotations_hidden_id:$chk_quotations_hidden_id,groupBy:$groupBy";
    let values = "id,quotation_number,commodity,pol,pod,por,pre_carriage,pre_mot,pre_pincode,fpd,on_carriage,on_mot,on_address,on_pincode,noOfPackage,cubic_meter,lcl_weight_kg,departure_date,type_shipment,commodity_details{container,quantity,weightPerContainer,cargo_value,temperature,humidity},pickUpPoint";
    let limitObject = {
      groupBy : "quotation_number", 
      delete_flag : 0,
      quotation_number : quotation_number,
      chk_quotations_hidden_id : chk_quotations_hidden_id
    }; 
    this.graphQlQuery = this.buoy.query(
      gql(`query user_quotation_details($delete_flag: Int! `+data_type+`){user_quotation_details(delete_flag:$delete_flag`+data_assign+`){` + values + `}}`),
      limitObject,
      {
      scope:      'user_quotation_details',
      fetchPolicy: "no-cache", //'cache-first' | 'cache-and-network' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
      subscribe:true,
      onChange:function(){  
          this.quotations_details.next(this.graphQlQuery);
          if(callback){
            callback(this.graphQlQuery);
          }
      }.bind(this)
    });
  }

  fetchData(query_name, values, limitObject, data_type, data_assign, callback) {
  
     this.resultData = this.buoy.query(
      gql(`query ` + query_name + `(`+data_type+`){` + query_name + `(`+data_assign+`){` + values + `}}`),
      limitObject,
      {
        scope: query_name,
        fetchPolicy: 'no-cache', //'cache-first' | 'cache-and-network' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
        subscribe:true,
        onChange:function(event){  
            if(callback){
              callback(this.resultData);
            }
        }.bind(this)
      });
  }


	setrefreshForm(data: any) {
		this.refreshForm.next(data);
	}

	getrefreshForm() {
		return this.refreshForm.asObservable();
	}

	setshowLoader(data: any) {
		this.showLoader.next(data);
	}

	getshowLoader() {
		return this.showLoader.asObservable();
	}

  setmodelFormValue(data) {
		this.modelFormValue.next(data);
	}

	getmodelFormValue() {
		return this.modelFormValue.asObservable();
  }
  
  setmineOthersFilter(filter: any) {
		this.mineOthersFilter.next(filter);
	}

	getmineOthersFilter() {
		return this.mineOthersFilter.asObservable();
  }


  setListViewData(data){
    this.ListViewData.next(data);
  }
  
  getListViewData(){
    return this.ListViewData.asObservable();
  }


  swapJsonKeyValue(json) {
		var ret = {};
		for (var key in json) {
			ret[json[key]] = key;
		}
		return ret;
  }
  

  setSelectedRows(rows: any) {
		this.selectedRows.next(rows);
  }
  
  getSelectedRows() {
		return this.selectedRows.asObservable();
  }
  


	setRefName(active_model: any) {
		this.activeRefName.next(active_model);
	}

	getRefName() {
		return this.activeRefName.asObservable();
  }
  
  
} 
